import React, {useEffect, Suspense, lazy} from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import WOW from "wowjs";

import MilestonePage from "./components/pages/Milestone";
import CoursePage from "./components/pages/Courses";
import MentorPage from "./components/pages/Mentor";
import BlogListing from "./components/pages/blogs/BlogListing";
import BlogDetail from "./components/pages/blogs/BlogDetail";
import TestimonialPage from "./components/pages/Testimonials";
import GalleryLisiting from "./components/pages/gallery/photo/Listing";
import GalleryDetailPage from "./components/pages/gallery/photo/GalleryDetail";
import EventListingPage from "./components/pages/gallery/events/Listing";
import EventDetailPage from "./components/pages/gallery/events/Details";
import ServiceListingPage from "./components/pages/ServiceListing";
import ServiceDetailPage from "./components/pages/ServiceDetail";
import HowWorkPage from "./components/pages/common/HowWeWork";
import ContactPage from "./components/pages/contact/contactUs";
import PartnerPage from "./components/pages/Partners";
import StudentEnrollmentPage from "./components/pages/student_enrolment/EnrollmentPage";
import CollegePartnerPage from "./components/pages/become-partner/CollegePartner";
import IndustryPartnerPage from "./components/pages/become-partner/IndustryPartner";
import StudentCertificate from "./components/pages/studentCertificate/Certificate";
import CourseDetail from "./components/pages/CourseDetail";
import Loading from "./components/common/Loader";
import InternCertificate from "./components/pages/studentCertificate/InternCertificate";


const App = () => {
  const RootLayout = lazy(() => import("./components/pages/Root"));
  const ErrorPage = lazy(() => import("./components/pages/Error"));
  const HomePage = lazy(() => import("./components/pages/Home"));
  const PageLayout = lazy(() => import("./components/pages/common/PageLayout"));


  useEffect(() => {
    const wow = new WOW.WOW({ live: false });
    wow.init();
  }, []);

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Suspense fallback={<Loading />}><RootLayout /></Suspense>} errorElement={<ErrorPage />}>
          <Route index element={<Suspense fallback={<Loading/>}><HomePage /></Suspense>} />
          <Route path="/about-us" element={<PageLayout page={"about-us"} />}/>
          <Route path="/winnovation-ecosystem" element={<PageLayout page={"ecosystem"} />} />
          <Route path="/privacy-policy"element={<PageLayout page={"privacy-policy"} />}/>
          <Route path="/terms-and-conditions" element={<PageLayout page={"terms-and-conditions"} />}/>
          <Route path="/milestones" element={<MilestonePage />}/>
          {/* <Route path="/courses" element={<CoursePage />} /> */}
          <Route path="/academy" element={<CoursePage />} />
          <Route path="/academy/:id" element= {<CourseDetail />} />
          {/* <Route path="/courses/:id" element= {<CourseDetail />} /> */}
          <Route path="/mentors" element={<MentorPage />} />
          <Route path="/blogs" element={<BlogListing />}/>
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route path="/testimonials/:type" element={<TestimonialPage />}/>
          <Route path="/gallery" element={<GalleryLisiting />} />
          <Route path="/gallery/:id" element={<GalleryDetailPage />}/>
          <Route path="/events" element={<EventListingPage />} />
          <Route path="/events/:id" element={<EventDetailPage />}/>
          <Route path="/services" element={<ServiceListingPage />}/>
          <Route path="/services/:id"element={<ServiceDetailPage />}/>
          <Route path="/technology-solutions/:id"element={<ServiceDetailPage />}/>
          <Route path="/how-we-work/:id" element={<HowWorkPage />} />
          <Route path="/contact-us" element={<ContactPage />}/>
          <Route path="/student-enrollment" element={<StudentEnrollmentPage />}/>
          <Route path="/become-industry-partners" element={<IndustryPartnerPage />}/>
          <Route path="/become-college-partners" element={<CollegePartnerPage />}/>
          <Route path="/student-certificates/:id" element={<StudentCertificate />}/>
          <Route path="/student-intern-certificates/:id" element={<InternCertificate />}/>
          <Route path="/our-partners" element={<PartnerPage />}/>    
        </Route>    
      </Routes>
    </BrowserRouter>
  );
};

export default App;
